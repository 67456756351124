@import "./variables";

#app-page {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: $primary !important;
  pre {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
  }

  h3 {
    margin-bottom: 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// @media all and (max-width: 1200px) {
//   /* screen size until 1200px */
//   body {
//     font-size: 1em; /* 1.5x default size */
//     p {
//       font-size: 1em;
//     }
//   }
// }
@media all and (min-width: 1200px) {
  /* screen size biggest 1200px */
  .container {
    max-width: 1900px !important;
  }
}
// @media all and (max-width: 1000px) {
//   /* screen size until 1000px */
//   body {
//     font-size: 1em; /* 1.2x default size */
//     p {
//       font-size: 1em;
//     }
//   }
// }
// @media all and (max-width: 500px) {
//   /* screen size until 500px */
//   body {
//     font-size: 0.8rem; /* 0.8x default size */

//     p {
//       font-size: 0.8em;
//     }
//   }
// }

ul {
  margin: 0;
}

.scroll::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0.25rem;
  // margin: 1.25rem;
}

@media (max-width: 570px) {
  .scroll::-webkit-scrollbar,
  body::-webkit-scrollbar {
    height: 0.25rem;
  }
}
@media (min-width: 571px) {
  .scroll::-webkit-scrollbar,
  body::-webkit-scrollbar {
    height: 0.5rem;
  }
}

.scroll::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 900;
  border-radius: 2.5rem;
}

.scroll {
  height: 100%;
  overflow: auto;
}

a {
  text-decoration-color: $accent;
}

.container-fluid {
  padding: 0 !important;
}

.my-l {
  margin: 2.5rem 0;
}
