@import "../../../variables";

.wave {
  svg > path.bg-body-bg {
    fill: $body-bg;
  }

  svg > path.bg-splash-2 {
    fill: $splash-color-2;
  }

  svg > path.bg-splash-1 {
    fill: $splash-color-1;
  }

  svg > path.bg-splash-3 {
    fill: $splash-color-3;
  }
}
