@import "../../variables";

.showcaseTile {
  position: relative;
  background-size: cover;
  padding: 0.1rem;

  &.selected::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 0px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 30px solid $body-bg;
    border-left: 20px solid transparent;
    top: 100%;
    right: calc(50% - 15px);
  }

  #techPills {
    position: absolute;
    top: 10px;
    left: 10px;

    .pill {
      background-color: $splash-color-1;
      border-radius: 3.5rem;
      max-width: 30px;
      transition: all 300ms ease-out;
      -moz-transition: all 300ms ease-out;
      -o-transition: all 300ms ease-out;
      -webkit-transition: all 300ms ease-out;
      -ms-transition: all 300ms ease-out;

      div {
        max-width: 0px;
        visibility: hidden;
        white-space: nowrap;
        overflow-x: hidden;
      }

      &:hover {
        max-width: 600px;

        div {
          padding-right: 10px;
          max-width: 600px;
          visibility: visible;
        }
      }
    }
  }
}

#showcaseDetails {
  overflow: hidden;
  max-height: 0px;
  background-color: $splash-color-3;

  transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  -webkit-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;

  &.hide {
    max-height: 0px;
    padding: 0 !important;
    margin: 0 !important;
  }

  &.show {
    max-height: 10000px;
  }
}
