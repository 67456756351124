@import "../../variables";

.cv-header {
  border-bottom: solid 0.1em $accent;
  margin: 1.5rem 0;
  padding-bottom: 0.6em;
}

.profile-section-head {
  margin-top: 1.5em;
}

.pcard-personal-meta {
  border-top: solid thin $accent;
  border-bottom: solid thin $accent;
  margin-bottom: -1px;
  padding: 0.25em 0;
}

@media (max-width: 1199px) {
  .profile-card {
    width: 220px;
  }
}
@media (max-width: 768px) {
  .profile-card {
    width: 100vw;
  }
}

h4 {
  font-weight: normal !important;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .left-bar {
    border-right: solid 0.1em $accent;
  }
}
