$light-theme-accent-light: #243b55;
$light-theme-accent-dark: #141e30;
$light-accent: #5ac595;
$body-bg: rgb(240, 240, 240);

$primary: rgb(35, 46, 58);
$secondary: rgb(129, 125, 126);
$accent: rgb(195, 160, 135);

$splash-color-1: rgb(35, 46, 58);
$splash-color-2: rgb(56, 78, 84);
$splash-color-3: rgb(140, 158, 162);

$theme-colors: (
  "primary": $accent,
  "danger": #ff4136,
  "side-color-dark": $primary,
  "side-color-light": $primary,
  "accent": $accent,
  "body-bg": $body-bg,
  "splash-1": $splash-color-1,
  "splash-2": $splash-color-2,
  "splash-3": $splash-color-3,
  "off-white": $body-bg,
  "light": rgb(181, 197, 201),
);

@import "../node_modules/bootstrap/scss/bootstrap";

$navbar-height: 40px;
