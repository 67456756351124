#featured-img {
  width: 400px;
  border-radius: 1.75rem;
  object-fit: fill;
}

@media (max-width: 1199px) {
  #featured-img {
    width: 320px;
  }
}

@media (max-width: 768px) {
  #featured-img {
    width: 85vw;
  }
}
