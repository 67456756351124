@import "../../../variables";

.skill-pill {
  border: solid thin $accent;
  color: $accent;
  padding: 0.1em 0.8em;
  margin: 0.2em;
  border-radius: 0.75rem;

  &.reverse {
    background-color: $accent;
    color: $body-bg;
  }
}
